<template>
  <base-page
    :title="$t('gatewayPoolManage.title')"
    :formData="formData"
    :config="config"
    @functionMethod="functionMethod"
    @search="search"
  >
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.poolList"
      :defaultParams="params"
      :columns="columns"
      rowName="code"
      v-model="tableRow"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
    </table-list>
    <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
    <dialog-import
      v-if="showDialogImport"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.poolImportGateway"
      :template="importTemplate"
      @cancel="showDialogImport = false"
      @confirm="importConfirm"
    />
  </base-page>
</template>

<script>
import Detail from './detail'

export default {
  components: {
    Detail,
  },
  data() {
    const lang = this.$util.getLocalStorage('lang')
    return {
      importTemplate: `/template/${lang}/gatewayPool.xlsx`,
      // 搜索
      formData: {},
      config: [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 6,
          tagProps: {
            placeholder: this.$t('gatewayPoolManage.nameOrNotes'),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 8,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 表格页
      tableRow: null,
      params: {
        type: 'GATEWAY',
      },
      columns: [
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
        // lang:IMIE
        {
          label: this.$t('gatewayPoolManage.code'),
          prop: 'code',
        },
        // lang:备注
        {
          label: this.$t('base.notes'),
          prop: 'notes',
        },
      ],
      // 表单页
      showDetail: false,
      // 导入
      showDialogImport: false,
    }
  },
  methods: {
    // 关闭详情页回调
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      this.$refs.tableList.searchList(this.formData, isActiveQuery)
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (method !== 'add' && method !== 'import' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'add':
        case 'edit':
        case 'view':
          this.showDetail = true
          break
        case 'del':
          this.deleteMethod()
          break
        case 'import':
          this.showDialogImport = true
          break
        default: // do something
      }
    },
    // 删除
    deleteMethod() {
      this.$element
        .showConfirm({
          content: this.$t('base.deleteConfirm'),
        })
        .then(() => {
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.poolDelete,
            data: this.tableRow,
          }).then(() => {
            // lang:删除成功
            this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
            this.search()
          })
        })
    },
    // 导入确认
    importConfirm() {
      this.showDialogImport = false
      this.$element.showMsg(this.$t('base.importSuccess'), 'success')
      this.search()
    },
  },
}
</script>